import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Benefits from "./Benefits";
import Results from "./Results";
import Accelerate from "./Accelerate";
import ContacForm from "./ContactForm";
import Blog from "../Telecom/Blog";

const Scaling = () => {
  return (
    <div>
      <Header />
      <div className="flex items-center justify-between bg-800 text-white" style={{background: '#264369'}}>
        <div className="w-full lg:w-1/2 p-10 mt-8">
          <h2 className="text-4xl font-semibold leading-tight mb-4 text-center sm:text-left">
            SCALING YOUR TEAM
          </h2>
          <p className="text-lg md:text-xl font-light mb-6 text-center sm:text-left">
            We help you scale your team with the right talent. Our team of experts will help you find the right talent for your project.
          </p>
        </div>
        <div className="hidden lg:block lg:w-1/2 fadeIn">
          {/* Image or Illustration goes here */}
          <img
            src="scaling1.jpg" // Replace with your image URL
            alt="Scaling Team"
            className="w-full h-auto bg-contain bg-right shadow-lg"
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-between px-8 py-12 bg-gray-50">
        {/* Image Section */}
        <div className="w-full lg:w-1/3 lg:ml-20 bg-800 rounded-lg shadow-lg mb-8 lg:mb-0">
          <img
            src="scaling2.jpg" // Replace with your image URL
            alt="Scaling Team"
            className="w-full h-80 bg-contain rounded-lg shadow-lg"
          />
        </div>

        {/* Text Section */}
        <div className="w-full lg:w-1/2">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4 text-center lg:text-left">
            Empower your team
          </h2>
          <hr className="border-t-4 border-sky-200 w-40 mb-6 mx-auto lg:mx-0" />
          <p className="text-lg text-gray-600 mb-6 text-center lg:text-left">
            We help our clients in upskilling their existing workforce to fill gaps in their skillsets by providing corporate training in technologies such as Artificial Intelligence, Machine Learning, Deep Learning, Robotics Process Automation, Internet of Things, Devops, etc.
          </p>
          <p className="text-lg text-gray-600 mb-6 text-center lg:text-left">
            With the help of internationally certified and experienced instructors, we provide successful learning and development solutions to IT & non-IT clients.
          </p>
        </div>
      </div>

      <Benefits />

      <Results />

      <Accelerate />

      <ContacForm />

      <Blog />

      <Footer />
    </div>
  );
};

export default Scaling;
