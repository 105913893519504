import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BenefitsSection from "./BenefitSection";
import TechDifferents from "./TechDifferents";
import Blog from "../Telecom/Blog";
import { Link } from "react-router-dom";

const Technology = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col md:flex-row items-center bg-white p-6 sm:p-10 md:p-20 gap-6 sm:gap-10 md:gap-20">
        {/* Text Section */}
        <div className="w-full md:w-3/4 text-center md:text-left">
          <h1 
            className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 mb-6 sm:mb-10 mt-20 md:mb-14" 
            style={{ lineHeight: "1.2", letterSpacing: "-1px", fontFamily: "Roboto", fontWeight: "700", fontStyle: "italic" }}
          >
            Technology <span className="text-blue-shade-2/90">Consulting</span> Services
          </h1>
          <p className="text-gray-700 text-sm sm:text-base md:text-lg leading-relaxed">
            Blue Data Digital provides cutting-edge technology consulting services, enabling organizations to harness the power of digital
            platforms, RPA, machine learning, and AI to drive operational efficiency and business growth. We help enterprises strategize, design,
            engineer, deploy, and manage machine learning solutions, ensuring a seamless transition from development to production.
          </p>
          <Link
            to="/contact"
            className="group inline-flex items-center text-white font-medium py-2 px-4 rounded-lg bg-blue-shade-2/90 hover:bg-gray-600 transition duration-300 mt-6"
            onClick={() => {
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            Get in touch
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-5 h-5 ml-2 transition-transform duration-300 group-hover:translate-x-2"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5l6 6m0 0l-6 6m6-6H3" />
            </svg>
          </Link>
        </div>

        {/* Image Section */}
        <div className="w-full md:w-1/3 md:mt-0 relative flex justify-center">
          <div className="relative mt-10 sm:mt-20 w-48 sm:w-64 md:w-full h-48 sm:h-64 md:h-full">
            <img
              src="T C-1.png"
              alt="Technology Consulting"
              className="w-full h-full object-cover rounded-full"
            />
          </div>
        </div>
      </div>

      <BenefitsSection />
      <TechDifferents />
      <Blog />
      <Footer />
    </>
  );
};

export default Technology;
