import React from 'react';

const Accelerate = () => {
  return (
    <div className="bg-white text-gray-900 px-8 py-12">
      <div className="lg:w-full mx-auto">
        {/* Card Wrapper */}
        <div className="bg-sky-50 rounded-lg shadow-lg p-8">
          {/* Header Section */}
          <h2 className="text-4xl font-semibold text-center text-green-400 mb-6">
            Our Training Services <br />
            <span style={{color: '#264369'}}>Accelerate Your Projects</span>
          </h2>

          <div className='mt-20'>
            {/* Horizontal Line with Dots */}
            <div className="flex items-center justify-center relative mb-6">
              {/* Grey Horizontal Line */}
              <div className="w-full h-1 bg-gray-300"></div>

              {/* Green Dots on the Line */}
              <div className="absolute top-1/2 left-0 right-0 flex justify-between w-3/4 px-4 transform -translate-y-1/2 ml-8 lg:mx-auto">
                <div className="bg-green-400 text-white rounded-full p-3"></div>
                <div className="bg-green-400 text-white rounded-full p-3"></div>
                <div className="bg-green-400 text-white rounded-full p-3"></div>
                <div className="bg-green-400 text-white rounded-full p-3"></div>
              </div>
            </div>

            {/* Text below the Line */}
            <div className="flex flex-wrap justify-center w-full mt-6 mx-auto">
              <div className='flex flex-col items-center justify-center mb-6 lg:mb-0 lg:w-1/4 px-4'>
                <h3 className="text-xl text-center font-semibold text-gray-800">Speed to Insight</h3>
                <p className="text-center text-gray-600 mt-2">
                  As the business begins to self-serve, you get reduced lead times on funding cycles and less back-and-forth communication, which results in increased speed to insight.
                </p>
              </div>
              <div className='flex flex-col items-center justify-center mb-6 lg:mb-0 lg:w-1/4 px-4'>
                <h3 className="text-xl text-center font-semibold text-gray-800">Increased Retention</h3>
                <p className="text-center text-gray-600 mt-2">
                  Our customers are experiencing improved retention as learners can automate manual data tasks that once encroached on their free time and caused a poor work-life balance.
                </p>
              </div>
              <div className='flex flex-col items-center justify-center mb-6 lg:mb-0 lg:w-1/4 px-4'>
                <h3 className="text-xl text-center font-semibold text-gray-800">ROI Tracking</h3>
                <p className="text-center text-gray-600 mt-2">
                  Our product and services include surveys that track the time and cost-saving statements users attribute to their use case development efforts.
                </p>
              </div>
              <div className='flex flex-col items-center justify-center mb-6 lg:mb-0 lg:w-1/4 px-4'>
                <h3 className="text-xl text-center font-semibold text-gray-800">Closing the Skill Gap</h3>
                <p className="text-center text-gray-600 mt-2">
                  It’s common to see technology advancement moving faster than skill development. Our products and services help close these skill gaps.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accelerate;
