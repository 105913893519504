import React from "react";

const ChoosingBdc = () => {
  return (
    <section className="py-8 px-4 sm:px-10 md:px-20 bg-gray-100 rounded-lg">
      {/* Title */}
      <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 text-left mb-6 sm:mb-10">
        Choosing Blue Data Digital entails
      </h2>

      {/* Benefits Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-10 md:gap-12 pt-4 sm:pt-8 pb-4 sm:pb-8">
        {/* Items */}
        {["Enhanced performance", "Predictive maintenance", "Flexibility", "Process optimization", "New business models", "Innovation"].map((title, index) => (
          <div key={index} className="flex flex-row items-start space-x-4">
            <div className="w-8 sm:w-10 h-8 sm:h-10 flex items-center justify-center text-white bg-blue-shade-2/90 font-bold text-lg rounded-md px-3 sm:px-4">
              {index + 1}
            </div>
            <div>
              <h3 className="text-base sm:text-lg font-bold text-gray-900">{title}</h3>
              <p className="text-gray-700 text-sm sm:text-base">
                {[
                  "Gaining full visibility into your assets and conditions allows your company to drastically enhance operational performance.",
                  "Analyzing operational data enables organizations to predict failures and ensure operational stability.",
                  "Through real-time IoT data analysis, companies can build new services such as dynamic scheduling, routing services, etc.",
                  "Real-time reporting helps optimize processes and eliminate operational bottlenecks.",
                  "Analyzing the data from sensors can help your organization discover new actionable insights and launch fresh modern business models.",
                  "Create innovative and connected products, while delivering more personalized and engaging experiences to your clients."
                ][index]}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ChoosingBdc;
