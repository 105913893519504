import React, { useState } from "react";
import { Link } from "react-router-dom";

const Technologies = () => {
  const [selectedTech, setSelectedTech] = useState("Cloud");

  // Technology details
  const techDetails = {
    Cloud: {
      title: "Cloud",
      description:
        "Empower your business with secure, scalable, and cost-efficient cloud solutions. Whether it's cloud migration, hybrid infrastructure, or cloud-native development, we help you optimize performance, enhance security, and drive digital transformation with the right cloud strategy.",
      keyOfferings: [
        "Cloud Strategy & Migration – Seamless transition to cloud platforms with minimal disruption",
        "Multi-Cloud & Hybrid Cloud Solutions – Tailored solutions across AWS, Azure, GCP, and private clouds",
        "Cloud Security & Compliance – Ensuring data protection and regulatory adherence",
        "Cloud Cost Optimization – Maximizing efficiency while minimizing cloud spend",
        "DevOps & Cloud Automation – CI/CD pipelines, Kubernetes, and infrastructure automation",
      ],
    },
    Automation: {
      title: "Automation",
      description:
        "Our automation solutions streamline business processes, reducing manual effort and increasing efficiency. By leveraging RPA, AI-driven workflows, and hyperautomation, we enable seamless operations, allowing teams to focus on strategic tasks.",
      keyOfferings: [
        "Robotic Process Automation (RPA) – Automating repetitive tasks with AI-driven bots",
        "Intelligent Process Automation (IPA) – AI-powered workflows for smarter decision-making",
        "Hyperautomation Solutions – Combining AI, RPA, and ML for end-to-end automation",
        "AI-driven Chatbots & Virtual Assistants – Enhancing customer interactions and support",
        "Automated IT Operations (AIOps) – Proactive monitoring and self-healing infrastructure",
      ],
    },
    "Data and AI": {
      title: "Data and AI",
      description:
        "We transform raw data into actionable insights with cutting-edge AI and data analytics solutions. Our expertise in data engineering, machine learning, and BI platforms helps businesses make informed decisions and drive innovation.",
      keyOfferings: [
        "Data Engineering & Warehousing – Scalable data pipelines and architectures",
        "Business Intelligence & Analytics – Actionable insights with dashboards & reporting",
        "AI/ML Model Development – Custom AI solutions for predictive analytics",
        "Data Governance & Security – Ensuring compliance and data integrity",
        "Real-Time & Big Data Processing – Handling large-scale, real-time data streams",
      ],
    },
    "Generative AI": {
      title: "Generative AI",
      description:
        "Leverage the power of Generative AI to automate content creation, enhance creativity, and optimize workflows. From AI-powered text, image, and code generation to personalized AI assistants, we enable businesses to innovate at scale.",
      keyOfferings: [
        "Custom AI Model Development – Tailored Gen AI solutions for businesses",
        "AI-powered Content Generation – Automating text, image, and video creation",
        "AI-driven Code Assistants – Enhancing developer productivity",
        "Conversational AI & Chatbots – Next-gen virtual assistants with advanced NLP",
        "Document & Workflow Automation – Automating business documentation processes",
      ],
    },
    "Data Engineering": {
      title: "Data Engineering",
      description:
        "Build scalable and secure data architectures to unlock business insights. Our data engineering solutions streamline data ingestion, processing, and storage, ensuring AI/ML readiness and real-time analytics for better decision-making.",
      keyOfferings: [
        "Data Architecture & Engineering – Designing modern, scalable data infrastructures for cloud and on-premise environments",
        "Data Integration & ETL/ELT Pipelines – Seamless data ingestion and transformation across multiple sources",
        "Big Data Processing & Real-time Analytics – Handling large-scale data with Apache Spark, Kafka, and real-time processing frameworks",
        "Data Warehousing & Data Lakes – Implementing cloud-based data lakes and warehouses (Snowflake, BigQuery, Redshift, Databricks)",
        "Data Governance, Security & Compliance – Ensuring regulatory compliance and secure data management",
        "AI-Ready Data Infrastructure – Creating AI/ML pipelines for predictive analytics and business intelligence",
      ],
    },
  };

  return (
    <div className="container mx-auto p-6">
      <div className="pt-10">
        <h2 className="text-4xl md:text-5xl font-bold text-blue-shade-2/90 text-left mb-10">
          Technologies
          <hr className="border-t-4 border-gray-950 w-20 md:w-40 mb-6" />
        </h2>
      </div>
      <div className="flex flex-col md:flex-row md:ml-10 md:p-6">
        <div className="md:w-1/4 mb-6 md:mb-0 flex flex-col space-y-4">
          <ul className="space-y-4">
            {Object.keys(techDetails).map((tech) => (
              <li key={tech}>
                <button
                  onClick={() => setSelectedTech(tech)}
                  className={`w-full flex items-center text-left px-4 py-2 rounded-lg font-medium text-lg transition-all duration-300 group w-max ${
                    selectedTech === tech
                      ? "bg-gray-900 text-white"
                      : "text-gray-900 hover:bg-gray-200"
                  }`}
                >
                  {tech}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-5 h-5 ml-2 transition-transform duration-300 group-hover:translate-x-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5l6 6m0 0l-6 6m6-6H3"
                    />
                  </svg>
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="md:w-3/4 p-4">
          <h2 className="text-2xl md:text-4xl font-bold text-gray-900">
            {techDetails[selectedTech].title}
          </h2>
          <p className="text-gray-700 mt-4 text-sm md:text-base">
            {techDetails[selectedTech].description}
          </p>
          <Link
            to={ selectedTech === "Cloud"
              ? "/cloud"
              : selectedTech === "Automation"
              ? "/rpa"
              : selectedTech === "Data and AI"
              ? "/ai"
              : selectedTech === "Generative AI"
              ? "/genai"
              // : selectedTech === "Data Engineering"
              // ? "/telecom"
              : "/" // Fallback if selectedTech doesn't match any defined case
            }
            className="mt-6 flex items-center text-white bg-blue-shade-2/90 font-medium py-2 px-4 rounded-lg hover:bg-gray-600 transition group w-max"
            onClick={() => {
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            Explore {selectedTech}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-5 h-5 ml-2 transition-transform duration-300 group-hover:translate-x-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5l6 6m0 0l-6 6m6-6H3"
              />
            </svg>
          </Link>
          <h3 className="text-lg md:text-xl font-bold mt-6">Key Offerings:</h3>
          <ul className="list-disc pl-6 mt-2 text-gray-700 text-sm md:text-base">
            {techDetails[selectedTech].keyOfferings.map((offering, index) => (
              <li key={index}>{offering}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
