import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faBook, faUsers } from '@fortawesome/free-solid-svg-icons';

const Benefits = () => {
  return (
    <div className="bg-900 text-white px-8 py-12" style={{background: '#264369'}}>
      {/* Header Section */}
      <h2 className="text-4xl font-semibold text-center text-green-400 mb-6 pt-4">
        Why Invest in Scaling Teams?
      </h2>
      <p className="text-lg text-center text-gray-300 mb-12">
        The most common roles benefiting from scaling teams include but are not limited to:
      </p>

      {/* Content Sections */}
      <div className="flex flex-col lg:flex-row justify-between pt-4">
        {/* First Column */}
        <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
          <div className="flex flex-col justify-center items-center items-start mb-4">
            <div className="text-5xl text-green-400 mr-4 mb-4">
              <FontAwesomeIcon icon={faDownload} className="h-15 w-15" />
            </div>
            <div className="mb-4 text-center">
              <h3 className="text-xl font-semibold text-white">Equip your analytics team</h3>
              <p className="text-gray-300 mt-2">
                Equip your analytics team with the training and education required to fuel measurable business value in efficiency, cost savings, and retention.
              </p>
            </div>
          </div>
        </div>

        {/* Second Column */}
        <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
          <div className="flex flex-col justify-center items-center items-start mb-4">
            <div className="text-5xl text-green-400 mr-4 mb-4">
              <FontAwesomeIcon icon={faBook} className="h-15 w-15" />
            </div>
            <div className="mb-4 text-center">
              <h3 className="text-xl font-semibold text-white">Drives data literacy</h3>
              <p className="text-gray-300 mt-2">
                Drives data literacy throughout your organization, ensuring consumers and creators of analytics have a foundation to grow a sustainable analytics capability.
              </p>
            </div>
          </div>
        </div>

        {/* Third Column */}
        <div className="w-full lg:w-1/3">
          <div className="flex flex-col justify-center items-center items-start mb-4">
            <div className="text-5xl text-green-400 mr-4 mb-4">
              <FontAwesomeIcon icon={faUsers} className="h-15 w-15" />
            </div>
            <div className="mb-4 text-center">
              <h3 className="text-xl font-semibold text-white">Strong internal analytics communities</h3>
              <p className="text-gray-300 mt-2">
                Strong internal and analytics communities are proven to drive engagement and culture, which is a critical component to making your analytics capability more sustainable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
