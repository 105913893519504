import React from 'react';

const Results = () => {
  return (
    <div className="bg-white text-gray-900 px-8 py-12">
      {/* Header Section */}
      <h2 className="text-4xl font-semibold text-center text-green-400 mb-6">
        Training Leads to <span className="text-sky-800">Real Results</span>
      </h2>
      <p className="text-lg text-center text-gray-600 mb-12">
        Training ROI for a single client over a one-year period.
      </p>

      {/* Results Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
        {/* First Result - ROI */}
        <div className="text-white p-6 rounded-lg shadow-lg text-center" style={{background: '#264369'}}>
          <h3 className="text-2xl font-semibold mb-4">ROI</h3>
          <p className="text-4xl font-bold text-green-400 mb-4">96%</p>
          <p className="text-gray-300">
            of respondents reported return of investment from their training.
          </p>
        </div>

        {/* Second Result - Time Savings */}
        <div className="text-white p-6 rounded-lg shadow-lg text-center" style={{background: '#264369'}}>
          <h3 className="text-2xl font-semibold mb-4">Time Savings</h3>
          <p className="text-4xl font-bold text-green-400 mb-4">89%</p>
          <p className="text-gray-300">
            of respondents reported time savings due to automation.
          </p>
        </div>

        {/* Third Result - Cost Savings */}
        <div className="text-white p-6 rounded-lg shadow-lg text-center" style={{background: '#264369'}}>
          <h3 className="text-2xl font-semibold mb-4">Cost Savings</h3>
          <p className="text-4xl font-bold text-green-400 mb-4">$4.8M</p>
          <p className="text-gray-300">
            Respondents reported an estimated total savings per year through optimization and automation.
          </p>
        </div>

        {/* Fourth Result - New Revenue */}
        <div className="text-white p-6 rounded-lg shadow-lg text-center" style={{background: '#264369'}}>
          <h3 className="text-2xl font-semibold mb-4">New Revenue</h3>
          <p className="text-4xl font-bold text-green-400 mb-4">$4.4M</p>
          <p className="text-gray-300">
            Respondents reported estimated net new revenue from new business insights.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Results;
