import React, { useState } from "react";

const DataAICapabilities = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const capabilitiesLeft = [
    {
      title: "Data Platform Modernization",
      info: "Align your tech stack to your business objectives. Initiate seamless data modernization, evolve enterprise data and artificial intelligence capabilities, and optimize your data & cloud ecosystems for the future.",
    },
    {
      title: "Unification of Data Models",
      info: "Standardizing data models across your organization to ensure consistency, accuracy, and ease of use for analytics.",
    },
    {
      title: "Data Monetization Strategy",
      info: "Unlocking the value of data by creating strategies to monetize data assets and generate new revenue streams.",
    },
    {
      title: "Advanced Analytics and Artificial Intelligence",
      info: "Applying advanced analytics and AI techniques to drive innovation, predict trends, and improve business outcomes.",
    },
  ];

  const capabilitiesRight = [
    {
      title: "Data Pipeline Development and Integration",
      info: "Building seamless pipelines to ingest, process, and transform data across various sources, ensuring efficiency and reliability.",
    },
    {
      title: "Data Compliance and Security",
      info: "Ensuring compliance with regulatory standards and implementing robust security measures to safeguard data.",
    },
    {
      title: "Insights and Business Intelligence",
      info: "Transforming raw data into actionable insights through business intelligence tools to support decision-making.",
    },
    {
      title: "Gen AI Across the Data Ecosystem",
      info: "Leveraging generative AI solutions to enhance creativity, automate processes, and transform the data lifecycle.",
    },
  ];

  return (
    <div className="bg-gray-100 py-10 px-6 md:px-20">
      <h2 className="text-4xl md:text-6xl font-semibold text-blue-900 mb-20 text-left pt-10">
        Our Data and AI Capabilities
      </h2>
      <div className="flex flex-col md:flex-row gap-8 md:gap-16 pb-10">
        {/* Left Column */}
        <div className="flex-1 space-y-8">
          {capabilitiesLeft.map((capability, index) => (
            <div
              key={`left-${index}`}
              className={`flex flex-col border-b border-gray-300 pb-4 ${
                activeIndex === `left-${index}` ? "text-blue-900" : "text-gray-900"
              }`}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">
                  {capability.title}
                </h3>
                <button
                  onClick={() =>
                    setActiveIndex(
                      activeIndex === `left-${index}` ? null : `left-${index}`
                    )
                  }
                  className="w-9 h-4.5 flex items-center justify-center text-white transition duration-300 focus:outline-none"
                >
                  {activeIndex === `left-${index}` ? <p className="bg-gray-300 w-full rounded-full text-gray-900">-</p> : <p className="bg-blue-900 w-full rounded-full">+</p> }
              </button>
              </div>
              {activeIndex === `left-${index}` && (
                <p className="text-base mt-4 mb-4 text-gray-700 w-3/4">{capability.info}</p>
              )}
            </div>
          ))}
        </div>

        {/* Right Column */}
        <div className="flex-1 space-y-8">
          {capabilitiesRight.map((capability, index) => (
            <div
              key={`right-${index}`}
              className={`flex flex-col border-b border-gray-300 pb-4 ${
                activeIndex === `right-${index}` ? "text-blue-900" : "text-gray-900"
              }`}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">
                  {capability.title}
                </h3>
                <button
                  onClick={() =>
                    setActiveIndex(
                      activeIndex === `right-${index}` ? null : `right-${index}`
                    )
                  }
                  className="w-9 h-4.5 flex items-center justify-center text-white transition duration-300 focus:outline-none z-10"
                >
               {activeIndex === `right-${index}` ? <p className="bg-gray-300 w-full rounded-full text-gray-900">-</p> : <p className="bg-blue-900 w-full rounded-full">+</p>}
                </button>
              </div>
              {activeIndex === `right-${index}` && (
                <p className="text-base mt-4 mb-4 text-gray-700 w-3/4">{capability.info}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataAICapabilities;