import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faRobot,
  faServer,
} from "@fortawesome/free-solid-svg-icons";

const ImpactSection = () => {
  const items = [
    {
      icon: faChartBar,
      title: "Data-driven excellence",
      description:
        "Be part of a legacy where countless businesses have been catapulted into the future, thriving with unparalleled agility, innovation, and growth.",
    },
    {
      icon: faRobot,
      title: "Leading the AI revolution",
      description:
        "Catapult your business to the zenith of technological evolution by seamlessly unifying AI and data, propelling you far ahead in the technology race.",
    },
    {
      icon: faServer,
      title: "End-to-end data solutions",
      description:
        "A full spectrum of data solutions, right from laying down the foundation to unlocking revenue-generating opportunities.",
    },
  ];

  return (
    <div className="py-16 px-6 md:px-20 mt-10 mb-20">
      <h2 className="text-4xl md:text-6xl font-semibold text-blue-900 text-left mb-20">
        Impact Where You Need It
      </h2>
      <div className="flex flex-col md:flex-row justify-start gap-8">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-start text-left w-full md:w-1/3"
          >
            {/* Icon */}
            <div className="text-blue-300 text-4xl md:text-6xl mb-6">
              <FontAwesomeIcon icon={item.icon} />
            </div>
            {/* Title */}
            <h3 className="text-lg md:text-xl font-semibold text-blue-900 mb-4">
              {item.title}
            </h3>
            {/* Description */}
            <p className="text-gray-700 leading-relaxed text-sm md:text-base">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImpactSection;
