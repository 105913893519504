import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";

export default function RequestForm() {
  const [captchaValue, setCaptchaValue] = useState(null);
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    message: "",
    acceptedPolicy: false,
    subscribeNewsletter: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleClear = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      message: "",
      acceptedPolicy: false,
      subscribeNewsletter: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      console.error("reCAPTCHA verification failed");
      return;
    }

    const endpoint = "https://formspree.io/f/xgvoyonz";

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Form submitted successfully:", data);
        toast.success("Form submitted successfully!");
        handleClear();
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error("Failed to submit form. Please try again.");
        handleClear();
      });
  };

  return (
    <section className="min-h-screen flex items-center justify-center bg-gray-100 px-6">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      
      <div className="flex flex-col md:flex-row w-full max-w-4xl mt-8 mb-8">
        
        {/* Left Section - "Request More Information" */}
        <div className="w-full md:w-1/3 text-start md:text-left mb-6 md:mb-0">
          <h2 className="text-2xl font-semibold" style={{ color: "#264369" }}>
            Request More <br /> Information
          </h2>
        </div>

        {/* Right Section - Form */}
        <div className="w-full md:w-9/10 md:ml-6">
          <h2 className="text-xl font-semibold text-center mb-4" style={{ color: "#FF5722" }}>
            We also Deliver Custom Training that is Tailored to Your Specific Needs.
          </h2>

          <form onSubmit={handleSubmit} className="space-y-4">
            
            {/* Name Fields */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">First Name<span className="text-red-500">*</span></label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Last Name<span className="text-red-500">*</span></label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
            </div>

            {/* Email */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Email<span className="text-red-500">*</span></label>
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            {/* Company */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Company<span className="text-red-500">*</span></label>
              <input
                type="text"
                placeholder="Company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            {/* Message */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Message</label>
              <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none h-24"
              ></textarea>
            </div>

             {/* Checkbox fields */}
            <div className="space-y-2">
              <label className="flex items-start space-x-2 text-xs text-gray-600">
                <input
                  type="checkbox"
                  name="acceptedPolicy"
                  checked={formData.acceptedPolicy}
                  onChange={handleChange}
                  required
                  className="h-4 w-4 text-blue-600 focus:ring-0"
                />
                <span>I have read and accepted the <a href="#" className="text-red-500 hover:underline">Legal Notice</a> and <a href="#" className="text-red-500 hover:underline">Privacy Policy</a><span className="text-red-500">*</span>.</span>
              </label>
              <label className="flex items-start space-x-2 text-xs text-gray-600">
                <input
                  type="checkbox"
                  name="subscribeNewsletter"
                  checked={formData.subscribeNewsletter}
                  onChange={handleChange}
                  className="h-4 w-4 text-blue-600 focus:ring-0"
                />
                <span>I agree to receiving newsletters and other occasional communications.</span>
              </label>
            </div>

            {/* reCAPTCHA */}
            <div className="flex justify-start">
              <ReCAPTCHA sitekey="6LdzA2MpAAAAAGZXjeK9ZYc34y2_38fflXR7ti4T" onChange={handleCaptchaChange} />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={!captchaValue}
              className="mt-4 px-4 py-2 bg-blue-600 text-white text-md hover:bg-blue-700 focus:outline-none disabled:bg-gray-400"
            >
              Submit
            </button>
          </form>
        </div>

      </div>
    </section>
  );
}
