import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import DataAICapabilities from "./DataAICapabilities";
import ImpactSection from "./ImpactSection";
import Blog from "../Telecom/Blog";
import { useNavigate } from "react-router-dom";

const DataAISolutions = () => {
  const navigate = useNavigate();

  return (
    <div className="mx-auto">
      <Header />
      <div
        className="relative w-full h-screen bg-cover bg-center flex items-center"
        style={{
          backgroundImage: `url('data 1.jpg')`,
        }}
      >
        <div className="absolute inset-0 bg-black/50"></div>
        <div className="relative z-10 text-white px-6 md:px-20 w-full md:w-3/4 lg:w-3/4">
          <h2 className="text-4xl md:text-6xl font-bold leading-tight mb-4">
            Data & AI Solutions
          </h2>
          <h4 className="text-xl md:text-3xl font-light mb-6">
            Elevate your business with transformative data-driven insights.
          </h4>
          <div className="mt-6">
            <button
              className="inline-flex items-center text-white font-medium py-2 transition group focus:outline-none"
              onClick={() => navigate("/contact")}
            >
              Connect with a Data & AI Lead
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-5 h-5 ml-2 bg-white text-gray-900 transition-transform duration-300 group-hover:translate-x-2 group-hover:shadow-lg rounded-lg p-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5l6 6m0 0l-6 6m6-6H3"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="py-20 px-6 md:px-20">
        <p className="text-2xl md:text-4xl leading-relaxed text-left text-blue-900 w-full md:w-9/10 mx-auto font-light">
          We establish a robust data foundation to craft your visionary strategy
          and drive sustainable data value creation—ensuring you stay at the
          forefront of innovation.
        </p>
      </div>
      <DataAICapabilities />
      <ImpactSection />
      <Blog />
      <Footer />
    </div>
  );
};

export default DataAISolutions;
